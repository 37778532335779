// import React from 'react';
// import MovebankDataBackground from '../components/MovebankDataBackground';
// import Mapbox from '../components/Mapbox';


export default function Home() {
    // console.log("C App - home");

    return

    // MovebankAPI(); // Call Movebank API - Bird Data 
    // console.log('This is your data', data)

    // return (
    //     <>
    //         <div class="">
    //             <MovebankAPI></MovebankAPI>
    //         </div>
    //     </>
    // )
    // return <Mapbox/>
}